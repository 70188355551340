/* eslint-disable */
import { useEffect } from 'react';
import { isProduction } from 'web/utils/constants';

declare global {
  interface Window {
    fbq: any;
    _fbq: any;
  }
}

const useFacebook = () => {
  useEffect(() => {
    if (!isProduction) return;

    (function (f?: any, b?: Document, e?: string, v?: string, n?: any, t?: any, s?: any) {
      if ((f as any).fbq) return;
      n = (f as any).fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!(f as any)._fbq) (f as any)._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      if (!b || !e) return;
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode?.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    window.fbq('init', '1273532113633671');
    window.fbq('track', 'PageView');
  }, []);
};

export default useFacebook;
