import React, { Suspense, lazy, useEffect } from 'react';
import 'regenerator-runtime';
import { Provider as StoreProvider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { IKContext } from 'imagekitio-react';
import branch from 'branch-sdk';
import 'web/utils/init-firebase';

import { getLcn } from 'web/utils/labeled-classnames';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'; // only included when process.env.NODE_ENV === 'development'
import useFirebase from './utils/use-firebase';
import store from './store';
import useWindowsOSStyles from './utils/use-windows-os-styles';
import { IS_E2E_TEST } from './utils/general';
import LoadingSpinner from './components/LoadingSpinner';
import { BRANCH_API_KEY, IMAGEKIT_BASE_URL } from './utils/constants';
import { useUserGtag } from './utils/analytics/google-analytics';
import { initializeGTM } from './utils/analytics/google-tag-manager/init-gts';

import { useInitFeatureFlags } from './store/feature-flags/slice';
import HeaderMeta from 'shared/components/HeaderMeta';

import 'react-loading-skeleton/dist/skeleton.css';
import useLiveNotifications from './utils/use-live-notifications';
import { Dresser } from './components/Dresser';
import ThreadableQueryClientProvider from 'shared/components/Providers/ThreadableQueryClientProvider';
import { useSetupInitialGenresOnLoad } from './api/genres';
import { usePersistUtmParams } from './api/marketing';
import useFacebook from './pages/Marketing/use-facebook';

const Router = lazy(() => import('./routers/Router'));
const showReactQueryDevtools = false && !IS_E2E_TEST;

initializeGTM();

const App = () => {
  useFirebase();
  useInitFeatureFlags();
  useUserGtag();
  useLiveNotifications();
  useSetupInitialGenresOnLoad();
  usePersistUtmParams();
  useFacebook();

  useEffect(() => {
    if (BRANCH_API_KEY) {
      branch.init(BRANCH_API_KEY, undefined, (_err, data) => {
        // TODO: Use data.data_parsed.user_id
      });
    }
  }, []);

  useWindowsOSStyles();

  const lcn = getLcn({
    reactAppContainer: ['bg-scene antialiased relative z-0'],
  });

  return (
    <IKContext urlEndpoint={IMAGEKIT_BASE_URL}>
      <div className={lcn.reactAppContainer}>
        <Suspense fallback={<LoadingSpinner />}>
          <HeaderMeta />
          <Router />
        </Suspense>
        <Toaster
          position="top-center"
          toastOptions={{
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
          }}
        />
      </div>
      <div id="modal-root" />
      <Dresser />
    </IKContext>
  );
};

export default () => (
  <StoreProvider store={store}>
    <ThreadableQueryClientProvider>
      <App />
      {showReactQueryDevtools && (
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      )}
    </ThreadableQueryClientProvider>
  </StoreProvider>
);
